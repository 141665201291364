.tech {
  font-size: 18px;
}

.tech__content {
  text-align: center;
  font-weight: 400;
}

.tech__content span {
  color: var(--main-color);
}

@media (min-width: 668px) and (max-width: 1079px) {
  .tech__content {
    font-size: 28px;
  }
}
