.header__icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130.5px;
  position: absolute;
  left: 60%;
  top: 48px;
}

.header__icons i {
  color: var(--white);
  padding: 6px;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid var(--white);
  border-radius: 50%;
  font-size: 36px;
}

.header__icons i:hover {
  color: var(--white);
  background-color: var(--main-color);
  border: 1px solid var(--white);
  transition: all 200ms ease-in;
}

.round-icons i {
  width: 60px;
  height: 60px;
  font-size: 35px;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .header__icons {
    left: 65%;
    width: 160px;
  }
}

@media (min-width: 1080px) {
  .header__icons {
    left: 65%;
    width: 200px;
  }

  .round-icons i {
    width: 60px;
    height: 60px;
    font-size: 35px;
  }
}
