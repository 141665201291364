@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  --main-color: #000000;
  --highlight-color: #b8d8a9;
  --secondary-color: rgb(83, 109, 133);
  --background-color-even: #f4f4f4;
  --white: rgb(244, 244, 244);
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  margin: auto;
  box-sizing: border-box;
  min-width: 325px;
  font-style: normal;
  background-color: rgb(244, 2544, 244, 0.3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body section:nth-child(2n) {
  background-color: var(--background-color-even);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  margin: 0;
}

h2 {
  padding: 6px 12px;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

h3 {
  margin: 0;
  margin: 12px 0px;
  color: var(--main-color);
  font-weight: 700;
  font-size: 18px;
  text-align: start;
  text-transform: uppercase;
}

h4 {
  font-weight: 700;
  font-size: 21px;
  color: var(--main-color);
  margin: 20px 0px;
  text-transform: uppercase;
}

h5 {
  color: var(--main-color);
  font-size: 21px;
  margin: 0;
  font-weight: 400;
}

h6 {
  font-weight: bold;
  margin: 10px 0px;
  font-size: 21px;
}

p {
  font-size: 18px;
}

a {
  text-decoration: none;
}

section {
  display: flex;
  flex-direction: column;
  padding: 25px 24px 50px 24px;
  align-items: center;
}

article {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;
}

article a {
  text-decoration: none;
  color: var(--main-color);
}

article p {
  text-align: start;
  margin: 0 0 10px 0;
}

h3 a:hover {
  color: var(--secondary-color);
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (min-width: 668px) and (max-width: 1079px) {
  h1 {
    font-size: 62px;
    line-height: 70px;
  }

  h2 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  section {
    padding: 25px 50px 50px 50px;
  }
}

@media (min-width: 1080px) {
  h1 {
    font-size: 60px;
    line-height: 90px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 18px;
    text-transform: uppercase;
  }

  h4 {
    font-weight: 700;
    font-size: 28px;
    color: var(--main-color);
    margin: 20px 0px;
    text-transform: uppercase;
  }

  h5 {
    color: var(--main-color);
    font-size: 22px;
    margin: 0;
    font-weight: 400;
  }

  h6 {
    font-weight: bold;
    margin: 10px 0px;
    font-size: 22px;
  }

  p {
    font-size: 18px;
  }

  a {
    text-decoration: none;
  }

  section {
    display: flex;
    flex-direction: column;
    padding: 25px 20% 50px 20%;
    align-items: center;
  }

  article p {
    font-size: 18px;
  }
}
