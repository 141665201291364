.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 302px;
  background-color: var(--secondary-color);
  padding: 0 24px 60px 24px;
}

.footer span {
  font-size: 25px;
  font-weight: bold;
  color: var(--white);
  margin-bottom: 40px;
}

.footer p {
  margin: 0;
  font-size: 21px;
  font-weight: 200;
  color: var(--white);
}

.footer h2 {
  background-color: var(--secondary-color);
}

footer a {
  text-decoration: none;
  color: var(--white);
}

footer a:hover {
  color: var(--highlight-color);
}

.flexbox {
  width: 100%;
}

.display {
  display: none;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .display {
    display: flex;
    flex-direction: column;
  }

  .hide {
    display: none;
  }

  .footer p {
    line-height: 30px;
  }
}

@media (min-width: 1180px) {
  .display {
    display: flex;
    flex-direction: column;
  }

  .hide {
    display: none;
  }

  .footer p {
    font-size: 18px;
    line-height: 50px;
  }
}
