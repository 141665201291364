.round-icons i {
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--white);
  border-radius: 50%;
  font-size: 20px;
}

.round-icons i:hover {
  color: var(--white);
  background-color: var(--main-color);
  border: 1px solid var(--white);
  transition: all 200ms ease-in;
}

.more__icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  width: 100%;
  color: var(--main-color);
  column-gap: 20px;
  margin-top: 20px;
}

.more__icons i {
  color: var(--main-color);
  border: 1px solid var(--main-color);
  height: 50px;
  width: 50px;
}

.more__icons a {
  color: var(--main-color);
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  text-align: center;
}

.more__icons p {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .more__icons {
    width: 60%;
  }
}

@media (min-width: 1080px) {
  .more__icons i {
    width: 120px;
    height: 120px;
    font-size: 62px;
  }

  .more__icons {
    width: 80%;
    column-gap: 40px;
  }

  .more__icons p {
    font-size: 16px;
  }
}
