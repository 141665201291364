@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
html {
  --main-color: #000000;
  --highlight-color: #b8d8a9;
  --secondary-color: rgb(83, 109, 133);
  --background-color-even: #f4f4f4;
  --white: rgb(244, 244, 244);
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  margin: auto;
  box-sizing: border-box;
  min-width: 325px;
  font-style: normal;
  background-color: rgb(244, 2544, 244, 0.3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body section:nth-child(2n) {
  background-color: #f4f4f4;
  background-color: var(--background-color-even);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  margin: 0;
}

h2 {
  padding: 6px 12px;
  background-color: #000000;
  background-color: var(--main-color);
  color: rgb(244, 244, 244);
  color: var(--white);
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

h3 {
  margin: 0;
  margin: 12px 0px;
  color: #000000;
  color: var(--main-color);
  font-weight: 700;
  font-size: 18px;
  text-align: start;
  text-transform: uppercase;
}

h4 {
  font-weight: 700;
  font-size: 21px;
  color: #000000;
  color: var(--main-color);
  margin: 20px 0px;
  text-transform: uppercase;
}

h5 {
  color: #000000;
  color: var(--main-color);
  font-size: 21px;
  margin: 0;
  font-weight: 400;
}

h6 {
  font-weight: bold;
  margin: 10px 0px;
  font-size: 21px;
}

p {
  font-size: 18px;
}

a {
  text-decoration: none;
}

section {
  display: flex;
  flex-direction: column;
  padding: 25px 24px 50px 24px;
  align-items: center;
}

article {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;
}

article a {
  text-decoration: none;
  color: #000000;
  color: var(--main-color);
}

article p {
  text-align: start;
  margin: 0 0 10px 0;
}

h3 a:hover {
  color: rgb(83, 109, 133);
  color: var(--secondary-color);
}

.sr-only {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (min-width: 668px) and (max-width: 1079px) {
  h1 {
    font-size: 62px;
    line-height: 70px;
  }

  h2 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  section {
    padding: 25px 50px 50px 50px;
  }
}

@media (min-width: 1080px) {
  h1 {
    font-size: 60px;
    line-height: 90px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 18px;
    text-transform: uppercase;
  }

  h4 {
    font-weight: 700;
    font-size: 28px;
    color: #000000;
    color: var(--main-color);
    margin: 20px 0px;
    text-transform: uppercase;
  }

  h5 {
    color: #000000;
    color: var(--main-color);
    font-size: 22px;
    margin: 0;
    font-weight: 400;
  }

  h6 {
    font-weight: bold;
    margin: 10px 0px;
    font-size: 22px;
  }

  p {
    font-size: 18px;
  }

  a {
    text-decoration: none;
  }

  section {
    display: flex;
    flex-direction: column;
    padding: 25px 20% 50px 20%;
    align-items: center;
  }

  article p {
    font-size: 18px;
  }
}


header {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2))),
    url("/pictures/DSC_3213.JPG");
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("/pictures/DSC_3213.JPG");
  background-size: cover;
  height: 520px;
  color: var(--white);
  padding: 39px 24px 29px 24px;
  background-position: center center;
}

.header__text-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 202px;
  margin-top: 60px;
  max-width: 50%;
}

.header__text-cont__portfolio-name {
  display: flex;
  flex-direction: row;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
  font-size: 13px;
  letter-spacing: 0.04em;
}

.header__text-cont__portfolio-name__port {
  font-weight: 400;
}

.header__text-cont__portfolio-name__name {
  font-weight: 700;
}

.header__text-cont__prof1__plus-prof2 {
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
}

/*-------------Header profile-img --------------*/

.header__profile-pic {
  position: absolute;
  left: 45%;
  top: 356px;
  z-index: 1;
}

.header__profile-pic img {
  object-fit: cover;
  width: 180px;
  height: 180px;
  border: 2px solid var(--white);
  border-radius: 50%;
}

/* ---------------------------Ipad adaptions -------------------*/

@media (min-width: 668px) and (max-width: 1079px) {
  header {
    padding: 49px 50px 29px 50px;
  }

  .header__text-cont {
    height: 250px;
    top: 121px;
    left: 50px;
  }

  .header__text-cont__portfolio-name {
    font-size: 18px;
  }

  .header__text-cont__prof1 {
    font-size: 40px;
  }

  .header__text-cont__prof1__plus-prof2 {
    font-size: 32px;
  }

  /*-------------Header profile-img --------------*/

  .header__profile-pic {
    left: 60%;
  }
}

@media (min-width: 1080px) {
  header {
    padding: 49px 20% 29px 20%;
  }

  .header__text-cont {
    left: 100px;
    max-width: 70%;
  }

  .header__text-cont__portfolio-name {
    font-size: 22px;
    line-height: 60px;
  }

  .header__text-cont__prof1__plus-prof2 {
    font-size: 40px;
    line-height: 80px;
  }

  /*-------------Header profile-img --------------*/

  .header__profile-pic {
    left: 60%;
  }
}

.header__icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130.5px;
  position: absolute;
  left: 60%;
  top: 48px;
}

.header__icons i {
  color: var(--white);
  padding: 6px;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid var(--white);
  border-radius: 50%;
  font-size: 36px;
}

.header__icons i:hover {
  color: var(--white);
  background-color: var(--main-color);
  border: 1px solid var(--white);
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.round-icons i {
  width: 60px;
  height: 60px;
  font-size: 35px;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .header__icons {
    left: 65%;
    width: 160px;
  }
}

@media (min-width: 1080px) {
  .header__icons {
    left: 65%;
    width: 200px;
  }

  .round-icons i {
    width: 60px;
    height: 60px;
    font-size: 35px;
  }
}

.intro {
  padding-top: 60px;
}

.intro__text {
  font-size: 18px;
  line-height: 29px;
}

.intro__text__line {
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  width: 40px;
  display: inline-block;
  margin-bottom: 4px;
}

.intro__text__dot {
  position: relative;
  left: -5px;
  height: 10px;
  width: 10px;
  background-color: var(--main-color);
  border-radius: 50%;
  display: inline-block;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .intro__text {
    font-size: 20px;
  }
}

@media (min-width: 1080px) {
  .intro__text {
    font-size: 22px;
    line-height: 50px;
  }

  .intro__text__line {
    position: relative;
    border: 3px solid var(--main-color);
    width: 70px;
    bottom: 0px;
  }

  .intro__text__dot {
    left: -10px;
    bottom: -1px;
    height: 16px;
    width: 16px;
  }
}

.tech {
  font-size: 18px;
}

.tech__content {
  text-align: center;
  font-weight: 400;
}

.tech__content span {
  color: var(--main-color);
}

@media (min-width: 668px) and (max-width: 1079px) {
  .tech__content {
    font-size: 28px;
  }
}

.projects__picture {
  position: relative;

  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.04em;

  height: 200px;
  width: 100%;
  color: var(--white);
}

.projects img {
  object-fit: cover;
  height: 200px;
  width: 100%;
}

.projects__picture-overlay {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.projects a {
  color: black;
  width: 100%;
}

.projects a:hover {
  color: var(--main-color);
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.projects__link-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
}

.otherprojects__link-container {
  width: 50%;
  display: flex;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
}

.projects__link {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.projects__link:hover {
  color: white;
  background-color: black;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.projects__languages {
  display: flex;
  width: 100%;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  margin: 12px 0 20px 0;
  flex-wrap: wrap;
  grid-row-gap: 4px;
  row-gap: 4px;
}

.projects__languages span {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 0.04em;
  padding: 5px 8px;
  background-color: var(--highlight-color);
}

.view-it-live:hover {
  -webkit-transition: all ease-in 200ms;
  transition: all ease-in 200ms;
}

.projects-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .projects-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }
}

@media (min-width: 1080px) {
  .projects__languages span {
    font-size: 14px;
  }

  .projects-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }
}

.thoughts__image {
  margin-bottom: 20px;
  background-size: cover;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.thoughts__thought {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.thoughts__thought p {
  line-height: 30px;
  margin: 0;
}

.thoughts a {
  color: black;
  width: 100%;
}

.thoughts a:hover {
  color: var(--main-color);
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.thoughts__more-thoughts-thought {
  margin-bottom: 20px;
}

.thoughts-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .thoughts-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }
}

@media (min-width: 1080px) {
  .thoughts__more-thougts-thought {
    width: 100%;
    margin-bottom: 20px;
    font-size: 18px;
  }

  .thoughts__more-thougts-thought span {
    color: var(--main-color);
  }

  .thoughts__thought p {
    font-size: 18px;
  }

  .thoughts-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
  }

  .thoughts__more-thoughts-thought {
    font-size: 18px;
  }
}

.skills {
  font-family: "Roboto", sans-serif;
}

.skills h2 {
  font-family: "Montserrat", sans-serif;
}

.skills__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  width: 100%;
  text-align: center;
}

.skills__content__header {
  color: var(--main-color);
  font-weight: 700;
  text-transform: uppercase;
}

.show-more {
  display: none;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .tech__content {
    font-size: 20px;
  }

  .show-more {
    display: block;
  }

  .skills__content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1080px) {
  .skills__content {
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 25px;
  }

  .skills__content__header {
    font-size: 25px;
  }

  .show-more {
    display: block;
  }
}

.round-icons i {
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--white);
  border-radius: 50%;
  font-size: 20px;
}

.round-icons i:hover {
  color: var(--white);
  background-color: var(--main-color);
  border: 1px solid var(--white);
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.more__icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  width: 100%;
  color: var(--main-color);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin-top: 20px;
}

.more__icons i {
  color: var(--main-color);
  border: 1px solid var(--main-color);
  height: 50px;
  width: 50px;
}

.more__icons a {
  color: var(--main-color);
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  text-align: center;
}

.more__icons p {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .more__icons {
    width: 60%;
  }
}

@media (min-width: 1080px) {
  .more__icons i {
    width: 120px;
    height: 120px;
    font-size: 62px;
  }

  .more__icons {
    width: 80%;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
  }

  .more__icons p {
    font-size: 16px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 302px;
  background-color: var(--secondary-color);
  padding: 0 24px 60px 24px;
}

.footer span {
  font-size: 25px;
  font-weight: bold;
  color: var(--white);
  margin-bottom: 40px;
}

.footer p {
  margin: 0;
  font-size: 21px;
  font-weight: 200;
  color: var(--white);
}

.footer h2 {
  background-color: var(--secondary-color);
}

footer a {
  text-decoration: none;
  color: var(--white);
}

footer a:hover {
  color: var(--highlight-color);
}

.flexbox {
  width: 100%;
}

.display {
  display: none;
}

@media (min-width: 668px) and (max-width: 1079px) {
  .display {
    display: flex;
    flex-direction: column;
  }

  .hide {
    display: none;
  }

  .footer p {
    line-height: 30px;
  }
}

@media (min-width: 1180px) {
  .display {
    display: flex;
    flex-direction: column;
  }

  .hide {
    display: none;
  }

  .footer p {
    font-size: 18px;
    line-height: 50px;
  }
}

